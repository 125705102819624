import styled, { css } from 'styled-components'

const type = {
  danger: css`
    color: #e74c3c;
  `,
}

const Button = styled.button`
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;
  height: auto;
  line-height: auto;
  color: #222;
  text-decoration: underline;

  ${props =>
    props.size === 'small' &&
    css`
      font-size: 13px;
      font-weight: 500;
    `}

  ${props =>
    props.type &&
    css`
      ${props => type[props.type]}
    `}
`

export default Button
