import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-right: 6px;
  position: relative;
  top: 0;
`

function Back({ children, to }) {
  return (
    <StyledLink to={to}>
      <Icon icon={faChevronLeft} /> {children}
    </StyledLink>
  )
}

export default Back
