import gql from 'graphql-tag'

export const getRmaQuery = gql`
  query getRma($id: ID!) {
    getRma(id: $id) {
      id
      shippingAddress {
        company
        firstname
        lastname
        street
        zip
        city
        countryCode
      }
      customer {
        shippingAddress {
          company
          firstname
          lastname
          street
          zip
          city
          countryCode
        }
      }
      reference
      serial
      article
      firmware
      error
      description
      purchaseDate
      saleDate
      creationDate
      status
      fiveYearWarranty
      costEstimateRequested
    }
  }
`
