import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import Container from '../Container'
import Title from '../Title'
import Intro from '../Intro'
import Checkbox from '../Checkbox'
import { getCustomerQuery, updateCustomerMutation } from './graphql'
import { getCustomerId } from '../../utils/auth'

// TODO: cleanup
// TODO: content loadings multiple times
function Account() {
  const [notification, setNotification] = useState(false)
  const { data, loading } = useQuery(getCustomerQuery, {
    variables: { id: getCustomerId() },
  })

  const [updateCustomer] = useMutation(updateCustomerMutation)

  useEffect(() => {
    if (data) {
      setNotification(data.getCustomer.emailNotification)
    }
  }, [data])

  if (loading) {
    return null
  }

  function onChange() {
    const newNotification = !notification
    setNotification(newNotification)
    updateCustomer({
      variables: {
        input: { id: getCustomerId(), emailNotification: newNotification },
      },
      refetchQueries: [
        { query: getCustomerQuery, variables: { id: getCustomerId() } },
      ],
    })
  }

  return (
    <Container>
      <Intro>
        <Title>Einstellungen</Title>
      </Intro>
      <Title size={2}>Benachrichtigungen</Title>
      <Checkbox
        value={notification}
        label="Status-Update per E-Mail erhalten"
        onClick={onChange}
      />
    </Container>
  )
}

export default Account
