import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  max-width: 890px;
  width: 890px;
  margin: 0 auto;
  padding: 2cm;
  background: #fff;
  border-radius: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.17);
`

function Preview({ children }) {
  return <Wrapper>{children}</Wrapper>
}

export default Preview
