import React from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/pro-light-svg-icons'
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'

import config from '../config'

const Wrapper = styled.nav`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`

const List = styled.ul`
  margin: 0 -0.5rem;
  padding: 0;
  list-style: none;
  display: flex;
`

const Item = styled.li``

const StyledLink = styled(NavLink)`
  padding: 0.5rem;
  color: inherit;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-right: 3rem;
  color: #222;
  opacity: 0.4;
  transition: opacity 0.25s ease, color 0.25s ease;

  &:hover,
  &.is-active {
    opacity: 1;
    color: ${config.colors.primary};
  }
`

const ExternalIcon = styled(FontAwesomeIcon)`
  color: inherit;
  font-size: 9px;
  position: relative;
  margin-left: 3px;
  top: -3px;
`

const Icon = styled.div`
  font-size: 24px;
  margin-right: 12px;
`

const Nav = () => (
  <Wrapper>
    <List>
      <Item>
        <StyledLink to="/" activeClassName="is-active">
          <Icon>
            <FontAwesomeIcon icon={faFileAlt} />
          </Icon>{' '}
          Rücksendungen
        </StyledLink>
      </Item>
      <Item>
        <StyledLink
          as="a"
          to=""
          href="https://ekey-shop.ch/account"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon>
            <FontAwesomeIcon icon={faUser} />
          </Icon>{' '}
          Konto <ExternalIcon icon={faExternalLink} />
        </StyledLink>
      </Item>
      <Item>
        <StyledLink
          as="a"
          to=""
          href="https://ekey-shop.ch/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon>
            <FontAwesomeIcon icon={faShoppingCart} />
          </Icon>{' '}
          Shop <ExternalIcon icon={faExternalLink} />
        </StyledLink>
      </Item>
    </List>
  </Wrapper>
)

export default Nav
