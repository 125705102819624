import React from 'react'

import PdfProvider from './PdfProvider'
import Pdf from './Pdf'

function PdfModule({ rmaId }) {
  return <PdfProvider rmaId={rmaId}>{rma => <Pdf rma={rma} />}</PdfProvider>
}

export default PdfModule
