import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'

import Label from '../Label'

import { formatAddress, formatRmaId } from '../../utils/rma'
import { format as formatDate } from '../../utils/date'
import Logo from '../Logo'
import Barcode from './Barcode'
import Grid from '../Grid'
import Checkbox from '../Checkbox'

const Wrapper = styled.div`
  @media screen {
    max-width: 100%;
    width: 890px;
    margin: 0 auto;
    /*
    padding: 2cm;
    background: #fff;
    border-radius: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.17);
    */
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5cm;
`

const HeaderLeft = styled.div``

const HeaderRight = styled.div`
  margin-left: auto;
  width: 41.666666%;
`

const StyledLogo = styled(Logo)`
  height: 1.5cm;
  margin-top: -0.5cm;
`

const Addresses = styled.div`
  display: flex;
  margin-bottom: 2cm;
`

const Address = styled.div`
  margin-right: 2cm;

  ${(props) =>
    props.right &&
    css`
      margin-left: auto;
      margin-right: 0;
      width: 41.666666%;
    `}
`

const Title = styled.h1`
  margin: 0 0 1cm 0;
  padding: 0;
`

const Content = styled.div``

const Section = styled.div``

const Checkboxes = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const CheckboxesItem = styled.li`
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`

function Pdf({ rma }) {
  useEffect(() => {
    // If `print/1` => print.
    const print = window.location.href.includes('print/1')
    if (print) {
      setTimeout(() => {
        window.print()
      }, 500)
    }
  }, [])

  return (
    <Wrapper>
      <Header>
        <HeaderLeft>
          <Barcode code={`RMA${formatRmaId(rma.id)}`} />
        </HeaderLeft>
        <HeaderRight>
          <StyledLogo />
        </HeaderRight>
      </Header>

      <Addresses>
        <Address>
          <Label>Absender</Label>
          <div
            dangerouslySetInnerHTML={{
              __html: formatAddress(rma.customer.shippingAddress),
            }}
          />
        </Address>

        {rma.shippingAddress && (
          <>
            <Address>
              <Label>Lieferadresse</Label>
              <div
                dangerouslySetInnerHTML={{
                  __html: formatAddress(rma.shippingAddress),
                }}
              />
            </Address>
          </>
        )}

        <Address right>
          <Label>Empfänger</Label>
          ekey biometric systems Schweiz AG
          <br />
          Schaanerstrasse 13
          <br />
          9490 Vaduz
          <br />
          Liechtenstein
        </Address>
      </Addresses>

      <Title>{`RMA${formatRmaId(rma.id)}`}</Title>

      <Content>
        <Section>
          <Grid margin>
            <Grid.Item size={6}>
              <Label>Erstelldatum</Label>
              {formatDate(rma.creationDate)}
            </Grid.Item>
          </Grid>
        </Section>

        <br />

        <Section>
          <Grid margin>
            <Grid.Item size={6}>
              <Label>Endkunden-Kommission</Label>
              {rma.reference}
            </Grid.Item>
          </Grid>
        </Section>

        <br />

        <Section>
          <Grid margin>
            <Grid.Item>
              <Label>Serien-Nr.</Label>
              {rma.serial}
            </Grid.Item>
            <Grid.Item>
              <Label>Artikel-Nr.</Label>
              {rma.article}
            </Grid.Item>
            <Grid.Item>
              <Label>Firmware</Label>
              {rma.firmware}
            </Grid.Item>
            <Grid.Item>
              <Label>Fehler-Nr.</Label>
              {rma.error}
            </Grid.Item>
            <Grid.Item>
              <Label>Kaufdatum</Label>
              {formatDate(rma.purchaseDate)}
            </Grid.Item>
            <Grid.Item>
              <Label>Verkaufsdatum</Label>
              {formatDate(rma.saleDate)}
            </Grid.Item>
          </Grid>
        </Section>

        <br />

        <Section>
          <Grid margin>
            <Grid.Item size={12}>
              <Label>Fehler-Beschreibung</Label>
              {rma.description}
            </Grid.Item>
          </Grid>
        </Section>

        <br />

        <Section>
          <Checkboxes>
            <CheckboxesItem>
              <Checkbox
                label="Garantie wurde um 5 Jahre verlängert."
                value={rma.fiveYearWarranty}
              />
            </CheckboxesItem>
            <CheckboxesItem>
              <Checkbox
                label="Kostenvoranschlag für Reparatur gewünscht."
                value={rma.costEstimateRequested}
              />
            </CheckboxesItem>
          </Checkboxes>
        </Section>
      </Content>
    </Wrapper>
  )
}

export default Pdf
