import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 3rem;
`

const Meta = styled.div`
  margin-left: auto;
  margin-top: -0.65rem;
`

function Intro({ children, meta }) {
  return (
    <Wrapper>
      {children}
      {meta && <Meta>{meta}</Meta>}
    </Wrapper>
  )
}

export default Intro
