import gql from 'graphql-tag'

export const updateCustomerMutation = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      emailNotification
    }
  }
`

export const getCustomerQuery = gql`
  query getCustomer($id: ID!) {
    getCustomer(id: $id) {
      emailNotification
    }
  }
`
