import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Title from '../Title'
import Intro from '../Intro'
import ButtonPrimary from '../ButtonPrimary'
import RmaList from './RmaList'

// TODO: put into graphql folder as fragment to use everywhere.
export const getRmasQuery = gql`
  query {
    getRmas {
      id
      shippingAddress {
        company
        firstname
        lastname
        street
        zip
        city
        countryCode
      }
      reference
      serial
      article
      firmware
      error
      description
      purchaseDate
      saleDate
      creationDate
      status
    }
  }
`

function RmaOverview() {
  const { data, loading, error } = useQuery(getRmasQuery)

  if (loading) {
    return 'Loading...'
  }

  if (error) {
    return 'Error...'
  }

  if (!data) {
    return 'No data...'
  }

  const rmas = data.getRmas.reduce((accumulator, currentValue) => {
    if (!currentValue.status) {
      currentValue.status = 'NEW'
    }

    if (!Array.isArray(accumulator[currentValue.status])) {
      accumulator[currentValue.status] = []
    }

    accumulator[currentValue.status].push(currentValue)

    return accumulator
  }, {})

  return (
    <div>
      <Intro
        meta={
          <Link to="/create">
            <ButtonPrimary>
              <FontAwesomeIcon icon={faPlus} />
            </ButtonPrimary>
          </Link>
        }
      >
        <Title>Rücksendungen</Title>
      </Intro>
      <Title size={2}>Erstellt</Title>
      <RmaList rmas={rmas['NEW'] || []} />
      <br />
      <br />
      <br />
      <Title size={2}>Bestätigt</Title>
      <RmaList rmas={rmas['ACCEPTED'] || []} />
      <br />
      <br />
      <br />
      <Title size={2}>In Arbeit</Title>
      <RmaList rmas={rmas['IN_PROGRESS'] || []} />
      <br />
      <br />
      <br />
      <Title size={2}>Versandt</Title>
      <RmaList rmas={[...(rmas['SENT'] || []), ...(rmas['ARCHIVED'] || [])]} />
    </div>
  )
}

export default RmaOverview
