import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'

import Label from '../Label'

import Input from '../Input'
import Dropdown from '../Dropdown'
import Button from '../Button'
import ButtonLink from '../ButtonLink'
import Grid from '../Grid'
import Actions from '../Actions'
import Modal from '../Modal'
import config from '../../config'
import { formatAddress } from '../../utils/rma'
import { countries } from '../../utils/countries'

const AddAddressButton = styled.button`
  cursor: pointer;
  height: 48px;
  width: 48px;
  padding: 0;
  border: none;
  opacity: 0.4;
  margin: 1.5rem auto 0 auto;
  display: block;
  outline: none;
  transition: opacity 0.25s ease;

  &:focus,
  &:hover {
    opacity: 1;
    color: ${config.colors.primary};
  }
`

const AddAddressButtonIcon = styled(FontAwesomeIcon)`
  font-size: 32px;
`

function RmaCreateShippingAddress({
  shippingAddress,
  onUpdateShippingAddress,
}) {
  const [address, setAddress] = useState(shippingAddress || {})
  const [isEditing, setIsEditing] = useState(false)

  const useAddress = address && Object.entries(address).length > 0

  function onChange(key, value) {
    setAddress({
      ...address,
      [key]: value,
    })
  }

  function onEdit() {
    setIsEditing(true)
  }

  function onCancel() {
    // TODO: Find more elegant way. Remove countryCode if city (required) is not provided.
    if (address.countryCode && !address.city) {
      setAddress({})
    }
    setIsEditing(false)
  }

  function onDelete() {
    setAddress({})
    onUpdateShippingAddress(null)
  }

  function onSave() {
    setIsEditing(false)
    onUpdateShippingAddress(address)
  }

  if (isEditing && !address.countryCode) {
    setAddress({
      ...address,
      countryCode: 'CH',
    })
  }

  return (
    <>
      <Label>Alternative Versandadresse</Label>
      {!useAddress && (
        <AddAddressButton onClick={onEdit}>
          <AddAddressButtonIcon icon={faPlusCircle} />
        </AddAddressButton>
      )}
      {useAddress && (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: formatAddress(address),
            }}
          />
          <ButtonLink type="danger" size="small" onClick={onDelete}>
            Löschen
          </ButtonLink>{' '}
          |{' '}
          <ButtonLink size="small" onClick={onEdit}>
            Bearbeiten
          </ButtonLink>
        </div>
      )}
      {isEditing && (
        <Modal>
          <form onSubmit={onSave}>
            <Grid margin>
              <Grid.Item size={6}>
                <Label>Firma</Label>
                <Input
                  value={address.company || ''}
                  onChange={(e) => onChange('company', e.target.value)}
                />
              </Grid.Item>
            </Grid>
            <Grid margin>
              <Grid.Item size={6}>
                <Label>Vorname*</Label>
                <Input
                  value={address.firstname || ''}
                  onChange={(e) => onChange('firstname', e.target.value)}
                  required
                />
              </Grid.Item>
              <Grid.Item size={6}>
                <Label>Nachname*</Label>
                <Input
                  value={address.lastname || ''}
                  onChange={(e) => onChange('lastname', e.target.value)}
                  required
                />
              </Grid.Item>
            </Grid>
            <Grid margin>
              <Grid.Item size={8}>
                <Label>Strasse*</Label>
                <Input
                  value={address.street || ''}
                  onChange={(e) => onChange('street', e.target.value)}
                  required
                />
              </Grid.Item>
              <Grid.Item size={4}>
                <Label>Haus-Nr.*</Label>
                <Input
                  value={address.streetNo || ''}
                  onChange={(e) => onChange('streetNo', e.target.value)}
                  required
                />
              </Grid.Item>
            </Grid>
            <Grid margin>
              <Grid.Item size={4}>
                <Label>PLZ*</Label>
                <Input
                  value={address.zip || ''}
                  onChange={(e) => onChange('zip', e.target.value)}
                  required
                />
              </Grid.Item>
              <Grid.Item size={8}>
                <Label>Ort*</Label>
                <Input
                  value={address.city || ''}
                  onChange={(e) => onChange('city', e.target.value)}
                  required
                />
              </Grid.Item>
            </Grid>
            <Grid margin>
              <Grid.Item>
                <Label>Land*</Label>
                <Dropdown
                  items={countries()}
                  selectedItem={address.countryCode || ''}
                  onChange={(e) => onChange('countryCode', e.target.value)}
                  required
                />
              </Grid.Item>
            </Grid>
            <Grid margin>
              <Grid.Item>
                <Actions
                  primary={[
                    <Button as="button" type="submit" size="small">
                      Speichern
                    </Button>,
                  ]}
                  secondary={[
                    <ButtonLink size="small" onClick={onCancel}>
                      Abbrechen
                    </ButtonLink>,
                  ]}
                />
              </Grid.Item>
            </Grid>
          </form>
        </Modal>
      )}
    </>
  )
}

export default RmaCreateShippingAddress
