import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { useParams } from 'react-router-dom'

import Pdf from './Pdf'
import { usePdfProvider } from './usePdfProvider'

const GlobalStyle = createGlobalStyle`
  /*
  @media screen {
    html, body {
      background: #efefef !important;
      min-height: 100vh;
    }
  }
  */

  html, body {
    background: #fff !important;
  }

  @media screen {
    body {
      margin: 40px;
    }
  }

  @media print {
    body {
      width: 21cm;
      height: 29.7cm;
      margin: 10mm 25mm 25mm 25mm;
      /* change the margins as you want them to be. */
    }
  }
`

function PdfRoute() {
  const { rmaId } = useParams()
  const { rma } = usePdfProvider(rmaId)

  if (!rma) {
    return null
  }

  console.log('DEBUG: rma:', rma)

  return (
    <>
      <GlobalStyle />
      <Pdf rma={rma} />
    </>
  )
}

export default PdfRoute
