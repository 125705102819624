import { useQuery } from '@apollo/react-hooks'

import { getRmaQuery } from '../../graphql/getRmaQuery'

export function usePdfProvider(rmaId) {
  const { data, error, loading } = useQuery(getRmaQuery, {
    variables: { id: rmaId },
  })

  if (loading) {
    return { loading }
  }

  if (error) {
    console.error(error)
    return { error }
  }

  const rma = data.getRma

  return {
    rma,
  }
}
