import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'

import * as date from '../../utils/date'
import { formatRmaId } from '../../utils/rma'

const Wrapper = styled.div``

const Nothing = styled.div`
  opacity: 0.4;
  font-weight: 500;
  font-style: italic;
`

const List = styled.ul`
  background: #fff;
  border-radius: 8px;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.17); */
  box-shadow: 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
`

const Item = styled.li`
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
  margin: 0;
  padding: 0;

  display: block;

  &:last-of-type {
    border-bottom: 0;
  }
`

const ItemAction = styled.div`
  background: transparent;
  /* border-left: 1px solid rgba(0, 0, 0, 0.075); */
  margin: -1rem;
  margin-left: 0;
  padding: 1rem;
`

const StyledLink = styled(Link)`
  display: block;
  padding: 1rem 1rem;
  transition: background-color 0.25s ease;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    background: rgb(251, 251, 251);
    color: inherit;
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Right = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const Title = styled.span`
  display: inline-block;
  margin-right: 10px;
`

const Description = styled.div`
  color: rgb(116, 116, 116);

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
  }

  @media (max-width: 560px) {
    display: none;
  }
`

const Light = styled.span`
  color: rgb(116, 116, 116);
  font-size: 15px;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
`

function RmaList({ rmas }) {
  if (!rmas || rmas.length <= 0) {
    return <Nothing>Keine RMA vorhanden.</Nothing>
  }

  return (
    <Wrapper>
      <List>
        {rmas.map(rma => (
          <Item key={rma.id}>
            <StyledLink to={`/rmas/${rma.id}`}>
              <Left>
                <Title>RMA{formatRmaId(rma.id)}</Title>
                <Description>
                  <Light>{rma.description} </Light>
                </Description>
              </Left>
              <Right>
                <Light>{date.format(rma.creationDate, 'dd. MMMM yyyy')}</Light>
                <ItemAction>
                  <Icon icon={faChevronRight} />
                </ItemAction>
              </Right>
            </StyledLink>
          </Item>
        ))}
      </List>
    </Wrapper>
  )
}

export default RmaList
