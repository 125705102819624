import React from 'react'

import RmaCreateProvider from './RmaCreateProvider'
import RmaCreate from './RmaCreate'

function RmaCreateRoute() {
  return (
    <RmaCreateProvider>
      {({ onCreate }) => <RmaCreate onCreate={onCreate} />}
    </RmaCreateProvider>
  )
}

export default RmaCreateRoute
