import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  width: 960px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`

function Container({ children, ...props }) {
  return <Wrapper {...props}>{children}</Wrapper>
}

export default Container
