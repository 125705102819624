import React, { useState } from 'react'
import styled from 'styled-components/macro'
import gql from 'graphql-tag'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { Redirect } from 'react-router-dom'

import Label from '../Label'

import Button from '../Button'
import Input from '../Input'
import Grid from '../Grid'
import { isLoggedIn } from '../../utils/auth'

const Info = styled.div`
  margin: 1.5rem 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);

  a:hover {
    color: #222;
  }
`
const ErrorMessage = styled.label`
  color: indianred;
`

const authMutation = gql`
  mutation Auth($email: String!, $password: String!, $type: UserTypeEnum!) {
    auth(email: $email, password: $password, type: $type) {
      token
    }
  }
`

function showErrorMessage(error) {
  if (typeof error !== 'undefined') {
    const LOGIN_ERROR_MESSAGE = 'Login error'
    if (error.graphQLErrors[0].message.includes(LOGIN_ERROR_MESSAGE)) {
      return (
        <div>
          <pre>
            <ErrorMessage>Zugangsdaten sind nicht korrekt.</ErrorMessage>
          </pre>
        </div>
      )
    }
  }
}

function AuthLogin() {
  const [auth, setAuth] = useState({
    email: '',
    password: '',
    type: 'CUSTOMER',
  })
  const [authMut, { error, data }] = useMutation(authMutation)
  const client = useApolloClient()

  if (isLoggedIn()) {
    return <Redirect to="/" />
  }

  function onChange(key, value) {
    setAuth({
      ...auth,
      [key]: value,
    })
  }

  function onSubmit(e) {
    e.preventDefault()
    authMut({
      variables: {
        email: auth.email,
        password: auth.password,
        type: auth.type,
      },
    })
  }

  if (data && data.auth && data.auth.token) {
    localStorage.setItem('token', data.auth.token)
    client.writeData({ data: { isAuth: true } })

    return <Redirect to="/" />
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid direction="vertical">
        <Grid.Item>
          <h1>RMA</h1>
        </Grid.Item>
        <Grid.Item>
          <Label>E-Mail</Label>
          <Input
            type="text"
            placeholder="E-Mail"
            onChange={(e) => onChange('email', e.target.value)}
            value={auth.email}
          />
        </Grid.Item>
        <Grid.Item>
          <Label>Passwort</Label>
          <Input
            type="password"
            placeholder="Passwort"
            onChange={(e) => onChange('password', e.target.value)}
            value={auth.password}
          />
        </Grid.Item>

        <Grid.Item>{showErrorMessage(error)}</Grid.Item>

        <Grid.Item>
          <Button type="submit" size="small">
            Anmelden
          </Button>
          <Info>
            Bitte verwenden Sie Ihre{' '}
            <a
              href="https://ekey-shop.ch/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ekey-shop.ch
            </a>{' '}
            Zugangsdaten.
            <br /> Sie möchten einen{' '}
            <a
              href="https://ekey-shop.ch/account"
              target="_blank"
              rel="noopener noreferrer"
            >
              Account erstellen
            </a>
            ?
          </Info>
        </Grid.Item>
      </Grid>
    </form>
  )
}

export default AuthLogin
