import React from 'react'
import styled from 'styled-components/macro'
import { Portal } from 'react-portal'

const Wrapper = styled.div`
  position: fixed;
  top: 20%;
  bottom: 20%;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.17);
  z-index: 1001;
  padding: 24px;
  width: 960px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
`

const Background = styled.div`
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`

// TODO: https://codesandbox.io/s/react-spring-modal-plnvn?from-embed
function Modal({ children }) {
  return (
    <Portal>
      <Wrapper>{children}</Wrapper>
      <Background />
    </Portal>
  )
}

export default Modal
