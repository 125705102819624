import React, { useEffect } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import gql from 'graphql-tag'
import { useMutation, useApolloClient } from '@apollo/react-hooks'

import { isAuth } from '../../utils/auth'

const sessionAuthMutation = gql`
  mutation SessionAuth($customerId: ID!, $session: String!) {
    sessionAuth(customerId: $customerId, session: $session) {
      token
    }
  }
`

export default function AuthSession() {
  const { customerId, session } = useParams()
  const history = useHistory()
  const [auth, { data }] = useMutation(sessionAuthMutation)
  const client = useApolloClient()

  useEffect(() => {
    auth({
      variables: {
        customerId,
        session,
      },
    })
  }, [auth, customerId, session])

  if (isAuth()) {
    history.push('/')
    return null
  }

  if (!customerId || !session) {
    history.push('/')
    return null
  }

  if (data && data.sessionAuth && data.sessionAuth.token) {
    localStorage.setItem('token', data.sessionAuth.token)
    client.writeData({ data: { isAuth: true } })
    return <Redirect to="/" />
  }

  return null
}
