import React from 'react'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { faPrint } from '@fortawesome/pro-light-svg-icons'

import Intro from '../Intro'
import ButtonPrimary from '../ButtonPrimary'
import Title from '../Title'
import { formatRmaId } from '../../utils/rma'

const Preview = styled.div`
  width: 100%;
  height: 600px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.17);
  margin-bottom: 2rem;
  position: relative;
`

const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  margin: 20px;
`

const PrintLink = styled.a`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const More = styled.div``

function RmaConfirmation() {
  const { id } = useParams()

  return (
    <div>
      <Intro>
        <div>
          <Title>
            RMA {formatRmaId(id)} <small>(Begleitschein)</small>
          </Title>
        </div>
      </Intro>
      Vielen Dank für das Einreichen.
      <br />
      <br />
      <strong>
        Bitte drucken Sie den Begleitschein aus und legen Sie den Ausdruck dem
        Paket bei.
      </strong>
      <br />
      <br />
      <br />
      <Preview>
        <PrintLink href={`/pdf/${id}/print/1`} target="_blank">
          <ButtonPrimary size="large">
            <FontAwesomeIcon icon={faPrint} />
          </ButtonPrimary>
        </PrintLink>
        <Iframe src={`/pdf/${id}`}></Iframe>
      </Preview>
      <More>
        <Link to="/create">
          <FontAwesomeIcon icon={faLongArrowRight} /> Einen neuen RMA erstellen
        </Link>
      </More>
    </div>
  )
}

export default RmaConfirmation
