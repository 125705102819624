import styled, { css } from 'styled-components/macro'

export default styled.h1`
  margin: 0 0 1rem 0;
  padding: 0;
  font-size: 42px;
  line-height: 1.1;
  font-weight: 600;
  color: inherit;
  font-size: 28px;

  @media (max-width: 440px) {
    font-size: 24px;
  }

  ${props =>
    props.size === 2 &&
    css`
      font-size: 22px;
      font-size: 18px;
      margin-bottom: 1.25rem;
      /* margin-bottom: 1rem; */

      @media (max-width: 440px) {
        font-size: 18px;
      }
    `}
`
