import React from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/pro-light-svg-icons'

import Intro from '../Intro'
import Title from '../Title'
import PdfModule from '../Pdf'
import { formatRmaId } from '../../utils/rma'
import ButtonPrimary from '../ButtonPrimary'
import Back from '../Back'
import { getRmaQuery } from '../../graphql/getRmaQuery'
import Preview from '../Preview'

const Wrapper = styled.div``

function RmaDetails({ match }) {
  const id = match.params.id
  const { data, loading, error } = useQuery(getRmaQuery, { variables: { id } })

  if (loading) {
    return null
  }

  if (error) {
    return 'Error...'
  }

  const rma = data.getRma

  return (
    <Wrapper>
      <Intro
        meta={
          <>
            <ButtonPrimary as="a" href={`/pdf/${id}/print/1`} target="_blank">
              <FontAwesomeIcon icon={faPrint} />
            </ButtonPrimary>
          </>
        }
      >
        <div>
          <Title>RMA{formatRmaId(id)}</Title>
          <Back to="/">Zurück zur Übersicht</Back>
        </div>
      </Intro>
      <Title size={2}>Fragen, Änderungen?</Title>
      Bei Fragen oder Änderungen{' '}
      <a
        href="https://ekey-shop.ch/support"
        target="_blank"
        rel="noopener noreferrer"
      >
        melden Sie sich gerne bei uns
      </a>
      . Bitte halten Sie Ihre RMA-Nummer bereit.
      <br />
      <br />
      <br />
      <Preview>
        <PdfModule rmaId={rma.id} />
      </Preview>
      <br />
      <br />
      <br />
    </Wrapper>
  )
}

export default withRouter(RmaDetails)
