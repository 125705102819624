import countries from 'i18n-iso-countries'

countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

export function formatRmaId(id) {
  return id.padStart(4, '0')
}

export function formatAddress(address) {
  if (!address || typeof address !== 'object') {
    return ''
  }

  let html = ``

  if (address.company) {
    html = `${html}${address.company}<br />`
  }

  if (address.firstname || address.lastname) {
    html = `${html}${address.firstname} ${address.lastname}<br />`
  }

  if (address.street) {
    html = `${html}${address.street} ${address.streetNo || ''}<br />`
  }

  if (address.zip || address.city) {
    html = `${html}${address.zip} ${address.city}<br />`
  }

  if (address.countryCode) {
    html = `${html}${getCountryFromCode(address.countryCode)}`
  }

  return html
}

export function getCountryFromCode(countryCode) {
  const country = countries.getName(countryCode, 'de')

  return country ? country : countryCode
}
