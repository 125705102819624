import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUserCog } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { NavLink } from 'react-router-dom'

import Logo from './Logo'
import Nav from './Nav'
import Container from './Container'
import * as auth from '../utils/auth'
import config from '../config'

const Wrapper = styled.header`
  display: flex;
  padding: 0 1.5rem;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 80px;
  background: rgb(251, 251, 251);
  will-change: box-shadow;
  transition: 0.25s ease;
  z-index: 100;

  ${props =>
    props.scrolled &&
    css`
      background: #fff;
      box-shadow: 0 0.22em 0.275em rgba(0, 0, 0, 0.075);
    `}
`

const Placeholder = styled.div`
  height: 80px;
  margin-bottom: 3rem;
`

const Main = styled.div`
  width: 200px;
`

const Center = styled(Container)`
  @media (max-width: 840px) {
    display: none;
  }
`

const Meta = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    color: inherit;
    text-decoration: none;
  }

  a.is-active {
    color: ${config.colors.primary};
  }
`

const StyledLogo = styled(Logo)`
  position: relative;
`

const Spacer = styled.span`
  display: inline-block;
  margin: 0 8px;
`

const LogoutLink = styled(Link)`
  color: inherit;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.25s ease;

  span {
    margin-right: 8px;
  }

  svg {
    font-size: 20px;
    position: relative;
    top: 0;
    transition: transform 0.25s ease;
  }

  &:hover {
    color: ${config.colors.primary};

    svg {
      transform: translateX(4px);
    }
  }
`

// TODO: Put into graphql folder or auth util to reuse.
const authQuery = gql`
  {
    isAuth @client
  }
`

function Header() {
  const {
    data: { isAuth },
  } = useQuery(authQuery)
  const [scrolled, setScrolled] = useState(false)

  // TODO: Put into custom hook
  useEffect(() => {
    function hasScrolled() {
      const top = window.pageYOffset
      const scrolledValue = top > 20
      setScrolled(scrolledValue)
    }

    hasScrolled()

    // TODO: Debounce
    window.addEventListener('scroll', hasScrolled, false)
  }, [])

  return (
    <>
      <Wrapper scrolled={scrolled}>
        <Main>
          <Link to="/">
            <StyledLogo />
          </Link>
        </Main>
        <Center>{isAuth && <Nav />}</Center>
        <Meta>
          <a
            href="https://ekey-shop.ch/support"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hilfe
          </a>
          {isAuth && (
            <>
              <Spacer>|</Spacer>
              <NavLink to="/settings" activeClassName="is-active">
                <FontAwesomeIcon icon={faUserCog} />{' '}
                <span>{auth.getFirstname()}</span>
              </NavLink>
              <Spacer>|</Spacer>
              <LogoutLink to="/auth/logout">
                <FontAwesomeIcon icon={faSignOutAlt} />
              </LogoutLink>
            </>
          )}
        </Meta>
      </Wrapper>
      <Placeholder />
    </>
  )
}

export default Header
