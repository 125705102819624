export const errors = [
  {
    code: 'EKYPRO0005',
    title: 'Sensor abgenutzt',
    description: 'Der Sensor weist eine Abnutzung auf',
    public: true,
  },
  {
    code: 'EKYPRO0008',
    title: 'Aktualisierung Gerät',
    description: 'Das Gerät weist keinen Fehler auf',
    public: false,
  },
  {
    code: 'EKYPRO0010',
    title: 'Anzeige SE defekt',
    description: 'Die Anzeige der Steuereinheit weist einen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0016',
    title: 'Sensorbruch',
    description: 'Der Sensor weist einen Haarriss auf',
    public: false,
  },
  {
    code: 'EKYPRO0019',
    title: 'Steckverbinder zu Tastenfeld defekt',
    description: 'Die Steckverbindung zur Codetastatur weist einen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0023',
    title: 'Conv. defekt/falsche Version',
    description:
      'Der Konverter weist einen Hardwarefehler bzw. eine Dateninkonsistenz auf',
    public: false,
  },
  {
    code: 'EKYPRO0042',
    title: 'E2 Codesperre',
    description:
      'Die Steuereinheit zeigt E2 und ist aufgrund der Eingabe eines falschen Codes gesperrt',
    public: true,
  },
  {
    code: 'EKYPRO0057',
    title: 'Flüssigkeitseintritt über Sensor',
    description:
      'Das Gerät weist einen Flüssigkeitseintritt über den Sensor auf',
    public: false,
  },
  {
    code: 'EKYPRO0061',
    title: 'FSM sendet kein Signal',
    description: 'Fehlendes Signal vom Funksendemodul',
    public: false,
  },
  {
    code: 'EKYPRO0062',
    title: 'Batterien leer',
    description: 'Die Batterien sind leer',
    public: false,
  },
  {
    code: 'EKYPRO0064',
    title: 'FZ-FSM nicht verheiratet',
    description: 'Fehlende Kopplung des Funkzylinders mit dem Funksendemodul',
    public: false,
  },
  {
    code: 'EKYPRO0065',
    title: 'FZ surrt aber reagiert nicht',
    description: 'Der Funkzylinder surrt, ist aber reaktionslos',
    public: false,
  },
  {
    code: 'EKYPRO0066',
    title: 'Gehäuse defekt',
    description: 'Das Gehäuse weist einen Defekt auf',
    public: true,
  },
  {
    code: 'EKYPRO0079',
    title: 'Kein Befund',
    description: 'Laut Analyseergebnis einwandfreie Funktion des Gerätes',
    public: false,
  },
  {
    code: 'EKYPRO0080',
    title: 'Verkabelung nicht OK',
    description: 'Die Verkabelung weicht von den ekey Vorgaben ab',
    public: false,
  },
  {
    code: 'EKYPRO0081',
    title: 'Kabelbruch',
    description: 'Das Kabel weist einen Bruch auf',
    public: false,
  },
  {
    code: 'EKYPRO0082',
    title: 'Gerät iO, Fehler bei Anwender',
    description: 'Fehlerursache nicht vom Gerät selbst verursacht',
    public: false,
  },
  {
    code: 'EKYPRO0087',
    title: 'Federklemme defekt',
    description: 'Die Federklemme weist einen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0097',
    title: 'Leihgerät retour',
    description: 'Leihgerät retour erhalten',
    public: false,
  },
  {
    code: 'EKYPRO0104',
    title: 'Kupplungsschieber def./abgen.',
    description: 'Der Kupplungsschieber weist einen Defekt/eine Abnutzung auf',
    public: false,
  },
  {
    code: 'EKYPRO0108',
    title: 'Mechanik des FZ defekt',
    description: 'Die Mechanik des Funkzylinders weist einen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0115',
    title: 'M-Knauf falsche Version',
    description: 'Mechanischer Knauf inkompatibel mit aktueller Version',
    public: false,
  },
  {
    code: 'EKYPRO0117',
    title: 'Netzteil defekt',
    description: 'Das Netzteil weist einen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0118',
    title: 'Geräte nicht miteinander gekoppelt',
    description: 'Fehlerhafte Kopplung der eingesetzten Geräte',
    public: false,
  },
  {
    code: 'EKYPRO0126',
    title: 'NU-Fehler',
    description: 'Die Steuereinheit zeigt "need update" an',
    public: true,
  },
  {
    code: 'EKYPRO0133',
    title: 'Platine defekt-Fehlanschluss',
    description:
      'Die elektronische Baugruppe ist wegen eines Fehlanschlusses defekt',
    public: false,
  },
  {
    code: 'EKYPRO0134',
    title: 'Platine defekt - Bauteil',
    description:
      'Es befindet sich ein defektes Bauteil an der elektronischen Baugruppe',
    public: false,
  },
  {
    code: 'EKYPRO0136',
    title: 'Platine defekt - mech.Beschädigung',
    description: 'Die elektronische Baugruppe ist mechanisch beschädigt',
    public: false,
  },
  {
    code: 'EKYPRO0144',
    title: 'Jumper Relais falsch gesetzt',
    description: 'Der Jumper am Relais ist an der falschen Position',
    public: false,
  },
  {
    code: 'EKYPRO0145',
    title: 'Mech. Relais defekt',
    description: 'Das mechanische Relais weist einen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0158',
    title: 'Sensor defekt',
    description: 'Der Sensor weist einen elektronischen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0161',
    title: 'Sensorfläche beschädigt',
    description: 'Die Sensorfläche weist eine Beschädigung auf',
    public: true,
  },
  {
    code: 'EKYPRO0163',
    title: 'Sonneneinstrahlung',
    description:
      'Bei Sonneneinstrahlung ist die Funktion des Gerätes beeinträchtigt',
    public: false,
  },
  {
    code: 'EKYPRO0164',
    title: 'Sensor verschmutzt',
    description: 'Der Sensor weist eine Verschmutzung auf',
    public: false,
  },
  {
    code: 'EKYPRO0168',
    title: 'Sicherung defekt',
    description: 'Die Sicherung der Versorgungsspannung ist defekt',
    public: false,
  },
  {
    code: 'EKYPRO0170',
    title: 'Geräte passen nicht zusammen',
    description: 'Inkompatibilität der eingesetzten Geräte',
    public: false,
  },
  {
    code: 'EKYPRO0173',
    title: 'Softwarefehler',
    description:
      'Aufgrund einer Dateninkonsistenz wird das Programm nicht ordnungsgemäß ausgeführt',
    public: false,
  },
  {
    code: 'EKYPRO0175',
    title: 'Taste/Schalter defekt',
    description: 'Die Taste/Schalter weist einen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0184',
    title: 'Versorgungsspannung defekt',
    description: 'Die Versorgungsspannung weist einen Defekt auf',
    public: true,
  },
  {
    code: 'EKYPRO0189',
    title: 'Verbindung zum Sensor defekt',
    description: 'Die Verbindung zum Sensor ist unterbrochen',
    public: false,
  },
  {
    code: 'EKYPRO0196',
    title: 'Zylinder defekt',
    description: 'Der mechanische Zylinder weist einen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0198',
    title: 'Flüssigkeitseintritt über Gehäuse',
    description:
      'Das Gerät weist einen Flüssigkeitseintritt über das Gehäuse auf',
    public: false,
  },
  {
    code: 'EKYPRO0199',
    title: 'Fehlbestellung',
    description: 'Fehlbestellung seitens Kunde',
    public: false,
  },
  {
    code: 'EKYPRO0200',
    title: 'Ware nicht benötigt',
    description: 'Ware wird vom Kunden zurück gegeben',
    public: false,
  },
  {
    code: 'EKYPRO0201',
    title: 'Falschlieferung',
    description: 'Falschlieferung seitens ekey',
    public: false,
  },
  {
    code: 'EKYPRO0202',
    title: 'Doppellieferung',
    description: 'Doppellieferung seitens ekey',
    public: false,
  },
  {
    code: 'EKYPRO0203',
    title: 'Paketdienst',
    description: 'Zustellungsfehler seitens des Paketdienstes',
    public: false,
  },
  {
    code: 'EKYPRO0204',
    title: 'Unbekannter Grund, Gutschrift',
    description: 'Die Ware ist ohne Angabe eines Grundes zur Gutschrift retour',
    public: false,
  },
  {
    code: 'EKYPRO0205',
    title: 'Lagerbereinigung Neuware',
    description: 'Lagerbereinigung von Neuware seitens Kunde',
    public: false,
  },
  {
    code: 'EKYPRO0206',
    title: 'Lagerbereinigung Gebrauchtware',
    description: 'Lagerbereinigung von Gebrauchtware seitens Kunde',
    public: false,
  },
  {
    code: 'EKYPRO0207',
    title: 'DRAM Fehler',
    description: 'DRAM Speicherbaustein korrupt',
    public: false,
  },
  {
    code: 'EKYPRO0208',
    title: 'Halbleiterrelais defekt',
    description: 'Das elektronische Halbleiterrelais weist einen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0209',
    title: 'Platine defekt - Überspannung',
    description:
      'Es befindet sich ein Überspannungsschaden an der elektronischen Baugruppe',
    public: false,
  },
  {
    code: 'EKYPRO0210',
    title: 'Status-LED defekt',
    description: 'Das Status-LED weist einen Defekt auf',
    public: false,
  },
  {
    code: 'EKYPRO0211',
    title: 'Adminfinger unbekannt',
    description: 'Die bereits eingespeicherten Adminfinger sind unbekannt',
    public: true,
  },
  {
    code: 'EKYPRO0212',
    title: 'Platine defekt - Lötstelle',
    description:
      'Es befindet sich eine fehlerhafte Lötstelle an der elektronischen Baugruppe',
    public: false,
  },
  {
    code: 'EKYPRO0213',
    title: 'Prozessorsysteme',
    description:
      'Die elektronische Baugruppe weist einen Prozessor- bzw. Speicherfehler auf',
    public: false,
  },
  {
    code: 'EKYPRO0214',
    title: 'FZ erkennt Karte nicht',
    description: 'Funkzylinder hat Erkennungsprobleme bei den Karten',
    public: false,
  },
  {
    code: 'EKYPRO0232',
    title: 'Fingerspeicher verloren',
    description: 'Korrupter Datenspeicher',
    public: false,
  },
  {
    code: 'EKYPRO0233',
    title: 'FAR-Fingerverwechslung',
    description: 'FAR-Fingerverwechslung bei Kunde',
    public: false,
  },
  {
    code: 'EKYPRO0234',
    title: 'Rückholaktion',
    description: 'Ware aufgrund einer Rückholaktion zurückgenommen',
    public: false,
  },
  {
    code: 'EKYPRO0235',
    title: 'Gutschrift abgelehnt',
    description: 'Gutschrift abgelehnt',
    public: false,
  },
  {
    code: 'EKEYCH1',
    title: 'E0 Fehler',
    description:
      'Die Steuereinheit zeigt "E0" an. Keine Datenverbindung zur Erfassungseinheit',
    public: true,
  },
  {
    code: 'EKEYCH2',
    title: 'Anderer Fehler',
    description:
      'Der Fehler wird in der Liste nicht aufgeführt. Siehe Beschreibung. ',
    public: true,
  },
  {
    code: 'EKEYCH3',
    title: 'Unbekannter Fehler',
    description: 'Der Fehler ist nicht identifizierbar',
    public: true,
  },
]
