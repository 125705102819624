import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { getRmasQuery } from './RmaOverview'

const addRmaMutation = gql`
  mutation AddRma($rma: RmaInput!) {
    addRma(rma: $rma) {
      id
    }
  }
`

function RmaCreateProvider({ children }) {
  const history = useHistory()
  const [addRma] = useMutation(addRmaMutation)

  function onCreate(rma) {
    addRma({
      variables: { rma },
      refetchQueries: [{ query: getRmasQuery }],
    })
      .then(result => {
        history.push(`/rmas/${result.data.addRma.id}/confirmation`)
      })
      .catch(err => {
        console.log('RmaCreateProvider.ts: catch: err:', err)
      })
  }

  return children({ onCreate })
}

export default RmaCreateProvider
