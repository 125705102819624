import styled, { css } from 'styled-components'

import config from '../config'

const Button = styled.button`
  margin: 0;
  padding: 0;
  /* TODO: Outline */
  background: ${config.colors.primary};
  color: #fff;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  padding: 1rem;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 3px;
  min-width: 148px;
  cursor: pointer;
  border-radius: 50px;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 12px 0px;

  ${props =>
    props.size === 'small' &&
    css`
      font-size: 13px;
      font-weight: 500;
      height: 44px;
      padding: 0.5rem 1rem;
      min-width: 120px;
    `}

  ${props =>
    props.type === 'light' &&
    css`
      background: #ccc;
      color: #222;
    `}
`

export default Button
