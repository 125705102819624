import React, { StrictMode } from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components/macro'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'

import 'normalize.css'

import Header from './Header'
import Container from './Container'
import RmaRoute from './Rma/RmaRoute'
import AuthLogin from './Auth/AuthLogin'
import AuthLogout from './Auth/AuthLogout'
import AuthSession from './Auth/AuthSession'
import PdfRoute from './Pdf/PdfRoute'
import Settings from './settings'
import * as auth from '../utils/auth'
import config from '../config'

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
  request: operation => {
    const token = localStorage.getItem('token')
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
  },
})

const GlobalStyle = createGlobalStyle`
  body {
    background: rgb(251, 251, 251);
    line-height: 1.4;
    color: rgb(27, 27, 27);
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    @media (max-width: 440px) {
     font-size: 15px;
    }
  }

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  a {
    transition: color 0.25s ease;
    color: inherit;
  }

  a:hover {
    color: ${config.colors.primary};
  }

  strong {
    font-weight: 500;
  }
`

function App() {
  // Check if we are logged in already.
  // TODO: Put into utils/auth
  // TODO: How long is the login token valid?
  const isAuth = auth.isAuth()
  client.writeData({ data: { isAuth } })

  const matchPdf = window.location.href.includes('/pdf')

  return (
    <StrictMode>
      <ApolloProvider client={client}>
        <ThemeProvider theme={{}}>
          <Router>
            <>
              <GlobalStyle />
              {!matchPdf && (
                <>
                  <Header />
                  <Container>
                    <Switch>
                      <Route path="/auth/session/:customerId/:session">
                        <AuthSession />
                      </Route>
                      <Route path="/auth/login">
                        <AuthLogin />
                      </Route>
                      <Route path="/auth/logout">
                        <AuthLogout />
                      </Route>
                      <Route path="/">
                        <RmaRoute />
                      </Route>
                    </Switch>
                  </Container>
                </>
              )}
              <Route path="/pdf/:rmaId">
                <PdfRoute />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
            </>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </StrictMode>
  )
}

export default App
