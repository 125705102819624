import React from 'react'
import styled from 'styled-components'

import logoImg from '../assets/logo.svg'

const Img = styled.img`
  display: block;
  height: 2.5rem;
  width: auto;

  @media (max-width: 440px) {
    height: 2.25rem;
  }
`

function Logo(props) {
  return <Img src={logoImg} alt="" {...props} />
}

export default Logo
