import React, { useRef, useEffect } from 'react'
import JsBarcode from 'jsbarcode'

function Barcode({ code }) {
  const ref = useRef(null)

  useEffect(() => {
    JsBarcode(ref.current, code, {
      height: 80,
    })
  }, [code])

  return <svg ref={ref}></svg>
}

export default Barcode
