import { format as dateFormat, isBefore } from 'date-fns'
import { de } from 'date-fns/locale'

export function format(timestamp, format = 'dd.MM.yyyy') {
  if (!timestamp) {
    return null
  }

  // Timestamp from database is in milliseconds.
  return dateFormat(new Date(timestamp * 1), format, { locale: de })
}

export function formatDatetime(timestamp, format = 'dd.MM.yyyy HH:mm') {
  return dateFormat(new Date(timestamp * 1), format, { locale: de })
}

export function isOverdue(timestamp) {
  return isBefore(new Date(timestamp * 1), new Date())
}
