import jwt from 'jsonwebtoken'

export function isLoggedIn() {
  const status = localStorage.getItem('token') !== null
  return status
}

export function isAuth() {
  return isLoggedIn()
}

function decodeToken() {
  const token = localStorage.getItem('token')
  return jwt.decode(token)
}

export function getCustomerId() {
  const payload = decodeToken()

  if (!payload) {
    return ''
  }

  return payload.id
}

export function getFirstname() {
  const payload = decodeToken()

  if (!payload) {
    return ''
  }

  return payload.firstname
}

export function getFullname() {
  const payload = decodeToken()

  if (!payload) {
    return ''
  }

  return `${payload.firstname} ${payload.lastname}`
}

export function getAddress() {
  const payload = decodeToken()

  if (!payload) {
    return ''
  }

  return payload.address
}
