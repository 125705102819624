import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons'
import { format } from 'date-fns'

import Select from '../Select'
import Label from '../Label'

import Title from '../Title'

import Button from '../Button'
import ButtonLink from '../ButtonLink'
import Input from '../Input'
import Grid from '../Grid'
import Checkbox from '../Checkbox'
import Textarea from '../Textarea'
import Actions from '../Actions'
import Back from '../Back'
import Intro from '../Intro'
import RmaCreateShippingAddress from './RmaCreateShippingAddress'
import * as auth from '../../utils/auth'
import { formatAddress } from '../../utils/rma'
import { errors } from '../../utils/errors'

const Wrapper = styled.div`
  padding-bottom: 60px;
`

const Section = styled.div`
  margin-bottom: 2rem;
`

const Icon = styled(FontAwesomeIcon)`
  margin-right: 12px;
  font-size: 20px;
`

const Checkboxes = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const CheckboxesItem = styled.li`
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const Description = styled.div`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
`

const AddressGrid = styled.div`
  @media (max-width: 600px) {
    > div {
      flex-direction: column;
    }

    > div > div {
      margin-left: 0;
    }

    > div > div:first-of-type {
      margin-bottom: 1.5rem;
    }
  }
`

const ItemGrid = styled.div`
  @media (max-width: 760px) {
    > div {
      flex-wrap: wrap;
    }

    > div > div {
      margin-bottom: 1rem;
    }

    > div > div:last-of-type {
      margin-bottom: 0;
    }
  }
`

const ErrorGrid = styled.div`
  @media (max-width: 760px) {
    > div > div {
      width: 100%;
    }
  }
`

function RmaCreate({ onCreate }) {
  const [rma, setRma] = useState({
    shippingAddress: null,
    fiveYearWarranty: false,
    costEstimateRequested: false,
  })

  const [termsAccepted, setTermsAccepted] = useState(false)

  const address = formatAddress(auth.getAddress())

  function onUpdateShippingAddress(shippingAddress) {
    setRma({
      ...rma,
      shippingAddress,
    })
  }

  function onChange(key, value) {
    setRma({
      ...rma,
      [key]: value,
    })
  }

  function onDateChange(key, value) {
    const date = new Date(value)
    setRma({
      ...rma,
      [key]: date,
    })
  }

  function onSubmit() {
    if (!termsAccepted) {
      alert('Bitte akzeptieren Sie die allgemeinen Servicebedingungen.')
      return
    }

    if (rma.shippingAddress) {
      const shippingAddress = { ...rma.shippingAddress }
      shippingAddress.street = `${shippingAddress.street} ${shippingAddress.streetNo}`
      delete shippingAddress.streetNo
      rma.shippingAddress = shippingAddress
    }

    onCreate(rma)
  }

  function onErrorChange(option) {
    setRma({
      ...rma,
      error: option.key,
    })
  }

  const filteredErrors = errors.filter((error) => error.public)
  const errorOptions = filteredErrors.map((error) => {
    return {
      key: error.code,
      value: `${error.title}`,
    }
  })

  const errorOptionSelection = errorOptions.find(
    (option) => option.key === rma.error
  )

  return (
    <Wrapper>
      <Intro>
        <div>
          <Title>Neue Rücksendung</Title>
          <Back to="/">Zurück zur Übersicht</Back>
        </div>
      </Intro>

      <Section>
        <Title size={2}>Adresse</Title>
        <AddressGrid>
          <Grid>
            <Grid.Item>
              <Label>Ihre Anschrift</Label>
              <div dangerouslySetInnerHTML={{ __html: address }} />
            </Grid.Item>
            <Grid.Item offset={1}>
              <RmaCreateShippingAddress
                shippingAddress={rma.shippingAddress}
                onUpdateShippingAddress={onUpdateShippingAddress}
              />
            </Grid.Item>
          </Grid>
        </AddressGrid>
      </Section>

      <br />
      <br />

      <Title size={2}>Details</Title>
      <Section>
        <Grid>
          <Grid.Item size={6}>
            <Label>Endkunden-Kommission</Label>
            <Input onChange={(e) => onChange('reference', e.target.value)} />
          </Grid.Item>
        </Grid>
      </Section>

      <Section>
        <ItemGrid>
          <Grid>
            <Grid.Item>
              <Label>Serien-Nr.</Label>
              <Input
                value={rma.serial}
                onChange={(e) => onChange('serial', e.target.value)}
              />
            </Grid.Item>
            <Grid.Item>
              <Label>Artikel-Nr.</Label>
              <Input
                value={rma.article}
                onChange={(e) => onChange('article', e.target.value)}
              />
            </Grid.Item>
            <Grid.Item>
              <Label>Firmware</Label>
              <Input
                value={rma.firmware}
                onChange={(e) => onChange('firmware', e.target.value)}
              />
            </Grid.Item>
            <Grid.Item>
              <Label>Kaufdatum (ekey)</Label>
              <Input
                type="date"
                onChange={(e) => onDateChange('purchaseDate', e.target.value)}
                max={format(new Date(), 'yyyy-MM-dd')}
              />
            </Grid.Item>
            <Grid.Item>
              <Label>Verkaufsdatum</Label>
              <Input
                type="date"
                onChange={(e) => onDateChange('saleDate', e.target.value)}
                max={format(new Date(), 'yyyy-MM-dd')}
              />
            </Grid.Item>
          </Grid>
        </ItemGrid>
      </Section>

      <Section>
        <ErrorGrid>
          <Grid>
            <Grid.Item size={6}>
              <Label>Fehler</Label>
              <Select
                options={errorOptions}
                selectedOption={errorOptionSelection}
                onChange={onErrorChange}
              />
            </Grid.Item>
          </Grid>
        </ErrorGrid>
      </Section>

      <Section>
        <Grid>
          <Grid.Item size={12}>
            <Label>Fehler-Beschreibung</Label>
            <Textarea
              value={rma.description}
              onChange={(e) => onChange('description', e.target.value)}
            />
            <Description>
              Bitte geben Sie den Fehler so genau wie möglich an. „Defekt“ oder
              „zur Reparatur“ reicht nicht aus.
            </Description>
          </Grid.Item>
        </Grid>
      </Section>

      <Section>
        <Checkboxes>
          <CheckboxesItem>
            <Checkbox
              label="Garantie wurde um 5 Jahre verlängert."
              value={rma.fiveYearWarranty}
              onClick={() =>
                onChange('fiveYearWarranty', !rma.fiveYearWarranty)
              }
            />
          </CheckboxesItem>
          <CheckboxesItem>
            <Checkbox
              label="Kostenvoranschlag für Reparatur gewünscht."
              value={rma.costEstimateRequested}
              onClick={() =>
                onChange('costEstimateRequested', !rma.costEstimateRequested)
              }
            />
          </CheckboxesItem>
        </Checkboxes>
      </Section>

      <br />
      <br />

      <Checkbox
        label={
          <>
            Ich akzeptiere die{' '}
            <a href="/ekey_RMA_Servicebedingungen.pdf" target="_blank">
              allgemeinen Servicebedigungen
            </a>
            .
          </>
        }
        value={termsAccepted}
        onClick={() => setTermsAccepted(!termsAccepted)}
      />

      <br />
      <br />

      <Actions
        primary={[
          <Button onClick={onSubmit}>
            <Icon icon={faPaperPlane} /> Einreichen
          </Button>,
        ]}
        secondary={[
          <Link to="/">
            <ButtonLink size="small">Abbrechen</ButtonLink>
          </Link>,
        ]}
      ></Actions>
    </Wrapper>
  )
}

export default RmaCreate
