import React from 'react'
import styled, { css } from 'styled-components/macro'

const align = {
  left: css`
    justify-content: flex-start;
  `,
  center: css`
    justify-content: center;
  `,
  right: css`
    justify-content: flex-end;
  `,
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -1rem;

  /* Prop: 'align' */
  ${props => align[props.align]}
`

const Primary = styled.div`
  display: flex;
  margin: 0 1rem;

  ${props =>
    props.align === 'right' &&
    css`
      order: 2;
    `}
`

const Secondary = styled.div`
  display: flex;
  margin: 0 0.5rem;

  & > * {
    margin: 0 0.5rem;
  }

  ${props =>
    props.align === 'right' &&
    css`
      order: 1;
    `}
`

/**
 * Actions
 *
 * ```
 * <Action primary={[<Button>Send</Button>]} secondary={[<Link>Cancel</Link>]}
 * ```
 */
const Actions = ({ primary, secondary, align = 'right' }) => (
  <Wrapper align={align}>
    <Primary align={align}>
      {primary.map((component, index) => (
        <div key={index}>{component}</div>
      ))}
    </Primary>
    <Secondary align={align}>
      {secondary.map((component, index) => (
        <div key={index}>{component}</div>
      ))}
    </Secondary>
  </Wrapper>
)

export default Actions
