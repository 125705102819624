import styled, { css } from 'styled-components'

export const labelStyles = css`
  font-size: 13px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 8px;
`

const Label = styled.div`
  ${labelStyles}
`

export default Label
