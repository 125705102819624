import styled, { css } from 'styled-components/macro'

export const Grid = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin: 0 -0.5rem;

  & > * {
    padding: 0 0.5rem;
  }

  /* TODO: More elegant solution? */
  ${props =>
    props.margin &&
    css`
      margin-bottom: 1rem;
    `}

  ${props =>
    !props.wrap &&
    css`
      flex-wrap: nowrap;
    `}

  ${props =>
    props.direction === 'vertical' &&
    css`
      flex-direction: column;

      & > * {
        padding: 0.5rem 0;
      }
    `}
`

export const Item = styled.div`
  ${props =>
    props.size &&
    css`
      @media (min-width: 600px) {
        flex-basis: ${(props.size / 12) * 100}%;
        max-width: ${(props.size / 12) * 100}%;
      }
    `}

  ${props =>
    props.offset &&
    css`
      margin-left: ${(props.offset / 12) * 100}%;
    `}
`

Grid.Item = Item

export default Grid
