import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { getRmaQuery } from '../../graphql/getRmaQuery'

function PdfProvider({ rmaId: providedRmaId, children }) {
  const { rmaId } = useParams()
  const { data, error, loading } = useQuery(getRmaQuery, {
    variables: { id: rmaId || providedRmaId },
  })

  if (loading) {
    return null
  }

  if (error) {
    console.error(error)
    return null
  }

  const rma = data.getRma

  return children(rma)
}

export default PdfProvider
