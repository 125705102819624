import styled, { css } from 'styled-components/macro'

import config from '../config'

const ButtonPrimary = styled.button`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${config.colors.primary};
  border: none;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 12px 0px;
  font-size: 24px;
  transition: transform 0.25s ease;
  transform: translateZ(0);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  outline: none;

  &:hover {
    transform: translateZ(0) scale(1.05);
    color: #fff;
  }

  ${props =>
    props.size === 'large' &&
    css`
      height: 112px;
      width: 112px;
      border-radius: 112px;
      font-size: 42px;
    `}
`

export default ButtonPrimary
