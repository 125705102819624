import React from 'react'
import styled from 'styled-components/macro'
import { Route, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import RmaOverview from './RmaOverview'
import RmaCreateRoute from './RmaCreateRoute'
import RmaDetails from './RmaDetails'
import RmaConfirmation from './RmaConfirmation'

const Wrapper = styled.div``

// TODO: Put into graphql folder or auth util to reuse.
const authQuery = gql`
  {
    isAuth @client
  }
`

// TODO: Put into auth util
const PrivateRoute = ({ isAuth, ...props }) =>
  isAuth ? <Route {...props} /> : <Redirect to="/auth/login" />

function RmaRoute() {
  const {
    data: { isAuth },
  } = useQuery(authQuery)

  return (
    <Wrapper>
      <PrivateRoute isAuth={isAuth} path="/" exact>
        <RmaOverview />
      </PrivateRoute>
      <PrivateRoute isAuth={isAuth} path="/rmas/:id" exact>
        <RmaDetails />
      </PrivateRoute>
      <PrivateRoute isAuth={isAuth} path="/rmas/:id/confirmation" exact>
        <RmaConfirmation />
      </PrivateRoute>
      <PrivateRoute isAuth={isAuth} path="/create" exact>
        <RmaCreateRoute />
      </PrivateRoute>
    </Wrapper>
  )
}

export default RmaRoute
